import Axios from "axios";
import BTFile from "@/entity/File";

interface UploadFileResponse {
  successful: boolean;
  uuid: string;
}

export default class FileManagerService {
  static async uploadOwnNda(
    file: File,
    token: string
  ): Promise<UploadFileResponse> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post("/api/v2/files/upload", formData, {
      params: {
        token,
      },
    });
    return response.data;
  }

  static async uploadNdaAsConsultant(
    file: File,
    contactId: number | string
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `/api/v2/files/upload/nda/${contactId}`,
      formData
    );
    return response.data;
  }

  static async getHistoryFiles(historyId: string | number): Promise<BTFile[]> {
    const response = await Axios.get(
      `/api/v2/files/history/${historyId}/attachments`
    );
    return response.data;
  }

  static async uploadHistoryFile(
    historyId: string | number,
    file: File
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `/api/v2/files/upload/history/${historyId}/attachments`,
      formData
    );
    return response.data;
  }

  static async deleteHistoryFile(fileId: number): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/files/history/attachments/${fileId}`
    );
    return response.data;
  }

  static async getExposeFiles(projectId: string | number): Promise<BTFile[]> {
    const response = await Axios.get(
      `/api/v2/files/project/${projectId}/expose`
    );
    return response.data;
  }

  static async getNdaFiles(projectId: string | number): Promise<BTFile[]> {
    const response = await Axios.get(`/api/v2/files/project/${projectId}/nda`);
    return response.data;
  }

  static async uploadExposeFile(
    projectId: string | number,
    file: File
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `/api/v2/files/upload/expose/${projectId}`,
      formData
    );
    return response.data;
  }

  static async uploadProjectNdaFile(
    projectId: string | number,
    file: File
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `/api/v2/files/upload/project-nda/${projectId}`,
      formData
    );
    return response.data;
  }

  static async deleteExposeFile(fileId: number): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/files/project/expose/${fileId}`
    );
    return response.data;
  }

  static async deleteNdaFile(fileId: number): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/files/project/project-nda/${fileId}`
    );
    return response.data;
  }

  static async uploadContactAttachment(
    contactId: number,
    file: File,
    fileName?: string | null
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);

    if (fileName) {
      formData.append("fileName", fileName);
    }

    const response = await Axios.post(
      `/api/v2/files/upload/contact/${contactId}/attachments`,
      formData
    );
    return response.data;
  }

  static async getContactAttachments(contactId: number): Promise<BTFile[]> {
    const response = await Axios.get(
      `/api/v2/files/contact/${contactId}/attachments`
    );
    return response.data;
  }

  static async deleteContactAttachment(fileId: number): Promise<void> {
    const response = await Axios.delete(
      `/api/v2/files/contact/attachments/${fileId}`
    );
    return response.data;
  }

  static openFile(fileId: string): void {
    window.open(`${window.location.origin}/api/v2/files/${fileId}`, "_blank");
  }

  static openNdaFile(contactId: number): void {
    window.open(
      `${window.location.origin}/api/v2/files/nda/signed/${contactId}`,
      "_blank"
    );
  }

  static async uploadEoiFileManually(
    projectId: number,
    buyerId: number,
    file: File
  ): Promise<void> {
    const formData = new FormData();
    formData.append("file", file);
    const response = await Axios.post(
      `/api/v2/files/eoi-v2/upload-manually`,
      formData,
      {
        params: {
          projectId,
          buyerId,
        },
      }
    );
    return response.data;
  }

  static async deleteEoiFile(fileId: number): Promise<void> {
    const response = await Axios.delete(`/api/v2/files/eoi-v2/${fileId}`);
    return response.data;
  }
}

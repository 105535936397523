























































import { Component, Mixins, Prop } from "vue-property-decorator";
import Tabs from "@/components/common/Tabs.vue";
import { Tab } from "@/interfaces";
import Contact, { getContactFullName } from "@/entity/Contact";
import ContactsService from "@/services/ContactsService";
import DialogMixin from "@/mixins/DialogMixin";
import ContactFormCard from "@/components/contact-form/ContactFormCard.vue";
import Loader from "@/components/common/Loader.vue";
import ActionButtonWithTooltip from "@/components/common/ActionButtonWithTooltip.vue";
import NdaSignedCell from "@/components/contact/NdaSignedCell.vue";
import NdaSentCell from "@/components/contact/NdaSentCell.vue";
import ChangeContactIsDisabledCell from "@/components/contact/ChangeContactIsDisabledCell.vue";

@Component({
  components: {
    Tabs,
    ContactFormCard,
    Loader,
    ActionButtonWithTooltip,
    NdaSignedCell,
    NdaSentCell,
    ChangeContactIsDisabledCell,
  },
})
export default class ContactLayout extends Mixins(DialogMixin) {
  @Prop({ type: Number, required: true }) contactId!: number;
  owners: Contact[] = [];

  contact: Contact | null = null;
  isContactFetching = false;

  get tabs(): Tab[] {
    return [
      {
        routeName: "contactDetail",
        name: this.$tc("detail"),
      },
      {
        routeName: "contactProspects",
        name: this.$tc("prospects"),
      },
      {
        routeName: "contactSearchProfiles",
        name: this.$tc("searchProfiles"),
      },
      {
        routeName: "contactHistory",
        name: this.$tc("history"),
      },
    ];
  }

  async fetchContact(): Promise<void> {
    try {
      this.isContactFetching = true;
      this.contact = await ContactsService.findOne(this.contactId);
      this.$replaceBreadcrumb({
        key: ":contactId",
        value: getContactFullName(this.contact),
      });
    } catch (e) {
      await this.$router.push({ name: "404" });
    } finally {
      this.isContactFetching = false;
    }
  }

  async fetchContacts(): Promise<void> {
    this.owners = await ContactsService.findAll();
  }

  onContactIsDisabledChange(value: boolean) {
    (this.contact as Contact).isDisabled = value;
  }

  onNdaApprovedChange(value: boolean) {
    (this.contact as Contact).ndaApproved = value;
  }

  onNdaUploaded() {
    (this.contact as Contact).ndaSigned = true;
    this.fetchContact();
    this.closeDialog();
  }

  onFormSuccess(): void {
    this.closeDialog();
    this.fetchContact();
  }

  created() {
    this.fetchContact();
    this.fetchContacts();
  }
}
